<template>
  <div>
    <invites />
  </div>
</template>

<script>
import Invites from "@/view/components/Invites/invites";

export default {
  components: { Invites },
};
</script>

<style scoped></style>
